
import { defineComponent, ref, onMounted } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import axios from "axios";
import { useRouter } from "vue-router";
import {mask} from 'vue-the-mask'
import AddTagsModal from "@/components/modals/forms/AddTagsModal.vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import {active} from "@/core/data/genericData";
export default defineComponent({
  name: "add-product-modal",
  components: {
    AddTagsModal
    },

  setup() {

    const active_list_data = active;
    const formRef = ref<null | HTMLFormElement>(null);
    const addProductCategoryModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const store = useStore();
    const loadingHsn = ref<boolean>(false);
    const router = useRouter(); 
    const shape = ref([]);

    onMounted( async () => {
      //const db_data = {}
      await setShapedata(shape.value)
      //await setTagData("")
      //await setHsnData("")
      await setIndustrydata(industry.value)
      await setFormdata(form.value)
      await setStagedata(stage.value)
      await setLengthdata(length.value)
      //await setParentData("")
    });
    
     const UploadImage = async (category_id) => {
      const db_data = {
        "category_id" : category_id,
        "file_name" : rawImg.value.file_name,
        "file_data" : rawImg.value.file_data,
        "user_id" : 1,
        }
       
          await store.dispatch(Actions.CUST_ADD_CAT_IMAGE, db_data).then(({ data }) => {
           if (data){
            setTimeout(() => {
              loading.value = false;
//
              Swal.fire({
                text: "Uploaded successfully.",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                hideModal(addProductCategoryModalRef.value);  
                router.go(0)           
              });
            }, 2000)          

          } else {

            loading.value = false;

            Swal.fire({
              text: "Sorry, looks like there are some errors detected, please try again.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            })  
            return false;
          }
      
      })
      .catch(({ response }) => {
        console.log(response);        
      });
    }


    let rawImg = ref() ;
    function encodeImagetoBase64L(element) {
        const image = element.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(image);
        
        reader.onload = (event) => {       
        var image_encode = String(reader.result)
        if (image_encode){
           var image_slice = image_encode.split(",")[1]
            rawImg.value = {
          file_name:element.target.files[0].name,
          file_data: image_slice,
          
          }
          console.log("helllo")
          console.log(image_slice)
        }
        else{
          rawImg.value = {
          file_name:element.target.files[0].name,
          file_data:reader.result,
          }
        }
       
       };
      
      }

    const setShapedata = async (data) => {
      const db_data = {}
        //loading.value = true;
        try {
          await store.dispatch(Actions.CUST_GET_SHAPE_CATEGORY, db_data).then(({ body }) => {
          shape.value = body.product;
          //loading.value = false;
        })
        .catch(({ response }) => {
          console.log(response);        
        });
        
      } catch (e) {
        console.log(e);
      }

    }

    const industry = ref([]);
    const setIndustrydata = async (param) => {
      const db_data = {}
        //loading.value = true;
        try {
            await store.dispatch(Actions.CUST_GET_INDUSTRY_CATEGORY, param).then(({ body }) => {
            industry.value = body.industry_types_pc;
            //loading.value = false;
        })
        .catch(({ response }) => {
          console.log(response);        
        });
        
      } catch (e) {
        console.log(e);
      }

    }

    const form = ref([]);
    const setFormdata = async (param) => {
      const db_data = {}
        //loading.value = true;
        try {
            await store.dispatch(Actions.CUST_GET_FORM_CATEGORY, param).then(({ body }) => {
            form.value = body.form;
            //loading.value = false;
        })
        .catch(({ response }) => {
          console.log(response);        
        });
        
      } catch (e) {
        console.log(e);
      }

    }

    const stage = ref([]);
    const setStagedata = async (param) => {
      const db_data = {}
        //loading.value = true;
        try {
            await store.dispatch(Actions.CUST_GET_Stage_CATEGORY, param).then(({ body }) => {
            stage.value = body.stage;
            //loading.value = false;
        })
        .catch(({ response }) => {
          console.log(response);        
        });
        
      } catch (e) {
        console.log(e);
      }

    }

    const length = ref([]);
    const setLengthdata = async (param) => {
      const db_data = {}
        //loading.value = true;
        try {
            await store.dispatch(Actions.CUST_GET_Length_CATEGORY, param).then(({ body }) => {
            length.value = body.length;
            //loading.value = false;
        })
        .catch(({ response }) => {
          console.log(response);        
        });
        
      } catch (e) {
        console.log(e);
      }

    }

    const parent = ref([]);
    const setParentData = async (data) => {
        loading.value = true;
        try {
          var values = {"search_term" : data}
          if(values.search_term){
            console.log("values")
            console.log(values)
            await store.dispatch(Actions.CUST_GET_CATEGORY, values).then(({ data }) => {
            parent.value = data.page_data;
            loading.value = false;
           
        })
        .catch(({ response }) => {
          console.log(response);        
        });
       }  
      } catch (e) {
        console.log(e);
      }
       
    }

 
    const tag = ref([]);
    const setTagData = async (data) => {
        //loading.value = true;
        try {
          var values = {"search_term" : data}
          if(values.search_term){
          await store.dispatch(Actions.CUST_GET_TAGLIST, values).then(({ data }) => {
          tag.value = data.page_data;
          //loading.value = false;
         
        })
        .catch(({ e }) => {
          console.log(e);        
        });
          }
      } catch (e) {
        console.log(e);
      }

    }

    const addtag : any[] = [];
    const addTags = async (data) => {
      // for (let i=0; i<data.length; i++){
      //   const db_data = {
      //     "tag_name": data[i],
      //     "created_user_id": "1",
      //     }
      //     try {
          
      //       await axios.post(
      //         process.env.VUE_APP_CUSTOME_APP_API_URL+"product/create_tag",
      //         db_data,
      //       {
      //         headers: {
      //           "x-api-key": "BvFPhsxvLB5cTrNLbWdGC2aayThFkC6Z4iPcXeDh"
      //         },
      //       }
      //     ).then(({ data }) => {
      //       addtag.push(data.is_success)
      //       console.log(db_data);
      //       console.log(data);
          
      //     })
      //     .catch(({ response }) => {
      //       console.log(response);        
      //     });
          
      //   } catch (e) {
      //     console.log(e);
      //   }
      // }
    }


    const hsn = ref([]);
     const setHsnData = async (data) => {
      const db_data = {
        search_term: ""
      }
        loading.value = true;
        try {
          var values = {"search_term" : data}
          await store.dispatch(Actions.CUST_HSN_CATEGORY, values).then(({ data }) => {
          hsn.value = data;
          console.log("HSN VALUE")
          console.log(hsn.value)
          loading.value = false;
         
        })
        .catch(({ response }) => {
          console.log(response);        
        });
        
      } catch (e) {
        console.log(e);
      }

    }

     const getHsnData = async () => {      
        
        // loadingHsn.value = true;
        
        // const db_data = { "search_term" : formData.value.hsn_select }
        // console.log("AAAAAAAAAAAAA")
        // console.log(db_data)
        // await store.dispatch(Actions.CUST_HSN_CATEGORY, db_data).then(({ data }) => {

        //     console.log(data);

        //   if(data){
        //     formData.value.hsn_code = data['hsn_code']
        //     formData.value.hsn_description = data['hsn_description']
        //     loadingHsn.value = false;

        //   }else{

        //     formData.value.hsn_description = ""

        //     Swal.fire({
        //       text: "Sorry, It's Invalid HSN!",
        //       icon: "error",
        //       buttonsStyling: false,
        //       confirmButtonText: "Ok, got it!",
        //       customClass: {
        //         confirmButton: "btn btn-primary",
        //       },
        //     });
            
        //     loadingHsn.value = false;

        //   }
        
        // })
        // .catch(({ response }) => {

        //   Swal.fire({
        //       text: "Sorry, It's Invalid Pincode!",
        //       icon: "error",
        //       buttonsStyling: false,
        //       confirmButtonText: "Ok, got it!",
        //       customClass: {
        //         confirmButton: "btn btn-primary",
        //       },
        //     });
            
        //     loadingHsn.value = false;

        // });

    }
    
    const formData = ref({
      name: "",      
      description: "",
      parent_select: "",
      shape_select: "",
      form_select: "",
      stage_select: "",
      length_select: "",
      industry_select: "",  
      tag_select:"",    
      hsn_select: "",
      hsn_code: "",
      hsn_description: "",
      active: "",
    });    

    const rules = ref({
      name: [
        {
          required: true,
          message: "Category Name is required",
          trigger: "change",  
          //pattern:"^[a-zA-Z]+$",        
        },        
      ],    
      description: [
        {
          required: true,
          message: "Description is required",
          trigger: "change",  
        }
      ],
      hsn_select: [
        {
          required: true,
          message: "Hsn is required",
          trigger: "change",  
        }
      ],
      shape_select: [
        {
          required: true,
          message: "Shape is required",
          trigger: "change",  
        }
      ],
      form_select: [
        {
          required: true,
          message: "Category Form is required",
          trigger: "change",  
        }
      ],
      stage_select: [
        {
          required: true,
          message: "Stage is required",
          trigger: "change",  
        }
      ],
      length_select: [
        {
          required: true,
          message: "Length is required",
          trigger: "change",  
        }
      ],
      industry_select: [
        {
          required: true,
          message: "Industry type is required",
          trigger: "click",  
        }
      ],
       tag_select: [
        {
          required: true,
          message: "Tag is required",
          trigger: "click",  
        }
      ],
    });

    const setCategoriesData = async (data) => {
      
      var tag_id_list : any [] = []
      var tag_text_list : any [] = []
      for (let j = 0; j < data.tag_select.length; j++){
        console.log(data.tag_select[j]);
        console.log(typeof(data.tag_select[j]))
        console.log(typeof(data.tag_select[j]) == 'string')
        if (typeof(data.tag_select[j]) == 'string')
        {
         
          tag_text_list.push(data.tag_select[j])
          console.log(tag_text_list)
        }
        else if(typeof(data.tag_select[j]) == 'number')
        {
          tag_id_list.push(data.tag_select[j])
        }

      }
      
      for (let i=0; i<tag_text_list.length; i++)
      {
        const db_data = {
          "tag_name": tag_text_list[i],
          "created_user_id": "1",
          }
           await store.dispatch(Actions.CUST_ADD_TAG, db_data).then(({ data }) => {
           
           addtag.push(data.tag_id);
           
          })
      }
    
      const merge = tag_id_list.concat(addtag);

      let parentcat = 0
      if (!data.parent_select)
      {
        parentcat = 0
         
      }
      else{
        parentcat = data.parent_select
      }
    
      const db_data = {
        "category_name": data.name,
        "category_description": data.description,
        "parent_id": parentcat,    
        "shape_id": data.shape_select,    
        "category_form": data.form_select,
        "category_stage": data.stage_select,
        "category_length_type": data.length_select,
        "category_industry_types":data.industry_select,
        //"level":"1",
        //"category_slug":"1",
        "tag_ids": merge,
        "created_user_id": "1",
        //"created_ip": "0.0.0.0",
        //"created_uagent": "Test",
        "active": data.active,
        "hsn_code": data.hsn_select,
        }
        
        await store.dispatch(Actions.CUST_ADD_CATEGORY, db_data).then(({ data }) => {
          if (data.category_id){
            UploadImage(data.category_id)
            setTimeout(() => {
              loading.value = false;
//
              Swal.fire({
                text: "Category has been successfully created.",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                hideModal(addProductCategoryModalRef.value);  
                router.go(0)           
              });
            }, 2000)          

          } else {

            loading.value = false;

            Swal.fire({
              text: "Sorry, looks like there are some errors detected, please try again.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            })  
            return false;
          }
      
      })
      .catch(({ response }) => {
        console.log(response);        
      });

    }

   
    const submit = async () => {

      loading.value = true;

      if (!formRef.value) {
        loading.value = false;
        return;
      }

      formRef.value.validate (async (valid) => {

        if (valid) {

          await setCategoriesData(formData.value);
        
        } else {

          loading.value = false;
          return false;
        }

      });    

      // formRef.value.validate((valid) => {
      //   if (valid) {
      //     loading.value = true;

      //     setTimeout(() => {
      //       loading.value = false;

      //       Swal.fire({
      //         text: "Company has been successfully created.",
      //         icon: "success",
      //         buttonsStyling: false,
      //         confirmButtonText: "Ok, got it!",
      //         customClass: {
      //           confirmButton: "btn btn-primary",
      //         },
      //       }).then(() => {
      //         hideModal(addCompanyModalRef.value);              
      //         router.go(0)
      //       });
      //     }, 2000);
      //   } else {
      //     Swal.fire({
      //       text: "Sorry, looks like there are some errors detected, please try again.",
      //       icon: "error",
      //       buttonsStyling: false,
      //       confirmButtonText: "Ok, got it!",
      //       customClass: {
      //         confirmButton: "btn btn-primary",
      //       },
      //     });
      //     return false;
      //   }
      // });
    };

    return {      
      formData,      
      rules,
      submit,
      formRef,
      loading,
      addProductCategoryModalRef,
      setFormdata,
      setLengthdata,
      setStagedata,
      setShapedata,
      setIndustrydata,
      setParentData,
      setHsnData,
      setTagData,
      getHsnData,
      addTags,
      encodeImagetoBase64L,
      active_list_data,
      form,
      length,
      stage,
      rawImg,
      addtag,
      shape,
      tag,
      industry,
      parent,
      hsn,
    };
  },
});
